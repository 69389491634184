<!--header start-->
<section class="agency header" id="header">
  <div
    class="agency bg mh-960"
    [ngStyle]="{
      'background-image': 'url(assets/images/agency/slider/slider.png)'
    }"
  >
    <div class="container" *ngIf="showContent">
      <div class="row">
        <div class="col-md-6">
          <div class="center-text">
            <div>
              <div class="header-text">
                <h1 class="text-uppercase">
                  <span class="bold-text">Kemmale</span> Foods
                </h1>
              </div>
              <div class="header-sub-text">
                <p>
                  Welcome to Kemmale Food and Beverages, where culinary
                  excellence meets innovation in every bite and sip. Our food
                  and beverage company is dedicated to providing you with an
                  unforgettable epicurean journey that tantalizes your taste
                  buds and quenches your thirst.
                </p>
              </div>
              <div class="link-horizontal">
                <ul>
                  <li>
                    <a
                      class="btn btn-default btn-gradient text-white"
                      [routerLink]="['gallery', 'products']"
                      >Products</a
                    >
                  </li>
                  <li>
                    <a
                      class="btn btn-default btn-gradient text-white active"
                      [routerLink]="['contact-us']"
                      >Contact us</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" *ngIf="!showContent">
      <app-breadcrumb></app-breadcrumb>
    </div>
  </div>
  <div class="set-abs abs-horizon-center girl-boy">
    <img
      src="assets/images/agency/site-images/product-slider.png"
      alt=""
      class="img-fluid d-block"
      aTilt
      data-tilt
      data-tilt-max="5"
      data-tilt-speed="400"
      data-tilt-perspective="500"
      *ngIf="showContent"
    />
    <div class="circle-container">
      <div class="gradient-circle-big"></div>
    </div>
    <div class="circle-container small">
      <div class="gradient-circle-small"></div>
    </div>
  </div>
</section>
<!--header end-->
