<!--video section start-->
<section
  class="agency video video-bg p-0"
  id="video"
  [ngStyle]="{
    'background-image': 'url(assets/images/agency/videos/video-background.png)'
  }"
>
  <div class="container">
    <div class="row">
      <div class="col-12 p-0">
        <div class="text-center">
          <div class="d-inline-block">
            <img
              src="assets/images/agency/videos/bg.png"
              alt=""
              class="img-fluid bg-video"
            />
            <div class="abs-center">
              <div>
                <div class="m-b-20">
                  <iframe
                    class="mfp-iframe"
                    allowfullscreen=""
                    title="Video"
                    src="//www.youtube.com/embed/UL9GIfcgMEg?autoplay=1"
                  ></iframe>
                </div>
                <div>
                  <a href="" class="video w-100">
                    <img
                      src="assets/images/agency/videos/title-icon.png"
                      alt=""
                      class="img-fluid m-b-20"
                    />
                  </a>
                  <div class="">
                    <h3 class="video-heading text-white text-center">
                      Amazing Videos & Presentations
                    </h3>
                    <h6 class="video-text text-white text-center">
                      We create. We Develop. We Inspire.
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--video section end-->
