import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-agency-header",
  templateUrl: "./agency-header.component.html",
  styleUrls: ["./agency-header.component.scss"],
})
export class AgencyHeaderComponent implements OnInit {
  @Input()
  showContent: boolean = true;
  @Input()
  content: Component;
  constructor() {}

  ngOnInit() {}
}
