<!--service section start-->
<section
  class="agency format service-bg"
  id="features"
  [ngStyle]="{
    'background-image':
      'url(assets/images/agency/service/services-backgrondpng.png)'
  }"
>
  <div class="container">
    <div class="row">
      <div class="col-md-4 offset-lg-1">
        <div class="center-text">
          <div class="format-container">
            <h6 class="borders-before text-uppercase">
              <span class="">Our services</span>
            </h6>
            <div class="format-head-text">
              <h2 class="about-font-header">
                Services we <span class="block-span"> provide</span>
              </h2>
            </div>
            <div class="format-sub-text">
              <p class="about-para">
                Our company is proud to provide an array of distinguished
                services designed to enhance your culinary and beverage
                experience.
              </p>
            </div>
            <a
              [routerLink]="['gallery', 'products']"
              class="btn btn-default btn-gradient text-white"
              >view more</a
            >
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-md-8">
        <div class="row">
          <div class="col-sm-4 center-content unset-height">
            <ul
              class="icon-collection"
              [@fadeInRight]="{
                value: fadeInRight,
                params: { timing: 3, delay: 0 }
              }"
            >
              <li class="about-icon">
                <a href="#" class="center-content"
                  ><img
                    src="assets/images/agency/site-images/bakery.png"
                    alt=""
                  />
                  <h5>Bakery Products</h5>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-sm-4 center-content unset-height">
            <ul
              class="icon-collection"
              [@fadeInRight]="{
                value: fadeInRight,
                params: { timing: 3, delay: 2 }
              }"
            >
              <li class="about-icon">
                <a href="#" class="center-content"
                  ><img
                    src="assets/images/agency/site-images/cakes.png"
                    alt=""
                  />
                  <h5>Cakes n Desserts</h5>
                </a>
              </li>
              <li class="about-icon">
                <a href="#" class="center-content"
                  ><img
                    src="assets/images/agency/site-images/beverages.png"
                    alt=""
                  />
                  <h5>Beverages</h5>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-sm-4 center-content unset-height">
            <ul
              class="icon-collection"
              [@fadeInRight]="{
                value: fadeInRight,
                params: { timing: 3, delay: 3 }
              }"
            >
              <li class="about-icon">
                <a href="#" class="center-content"
                  ><img
                    src="assets/images/agency/site-images/restaurant.png"
                    alt=""
                  />
                  <h5>Fine Dine Restaurant</h5>
                </a>
              </li>
              <li class="about-icon">
                <a href="#" class="center-content"
                  ><img
                    src="assets/images/agency/site-images/catering.png"
                    alt=""
                  />
                  <h5>Catering Services</h5>
                </a>
              </li>
              <li class="about-icon">
                <a href="#" class="center-content"
                  ><img
                    src="assets/images/agency/site-images/party.png"
                    alt=""
                  />
                  <h5>Party Events</h5>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--service section end-->
