<!-- blog section start-->
<section
  class="agency blog blog-bg"
  id="blog"
  [ngStyle]="{
    'background-image': 'url(assets/images/agency/blog/background.png)'
  }"
>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <div class="title">
          <div class="main-title">
            <h2 class="text-capitalize text-center">Savoring Excellence</h2>
          </div>
          <div class="sub-title">
            <p class="text-center">Our Culinary Journey</p>
          </div>
        </div>
      </div>
      <div class="col-12">
        <iframe
          class="mfp-iframe"
          allowfullscreen=""
          title="Video"
          src="//www.youtube.com/embed/UL9GIfcgMEg?autoplay=1"
        ></iframe>
      </div>
    </div>
  </div>
</section>
<!-- blog section end-->
