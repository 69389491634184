import { PortfolioComponent } from "./portfolio/portfolio.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AgencyComponent } from "./layouts/agency/agency.component";
import { AboutUsComponent } from "./shared/components/pages/about-us/about-us.component";
import { ContactComponent } from "./shared/components/pages/contact/contact.component";
export const routes: Routes = [
  {
    path: "",
    component: AgencyComponent,
  },
  {
    path: "gallery",
    component: PortfolioComponent,
    loadChildren: () =>
      import("./portfolio/portfolio.module").then((m) => m.PortfolioModule),
    data: {
      breadcrumb: "Home",
    },
  },
  {
    path: "about-us",
    component: AboutUsComponent,
    data: {
      title: "ABOUT US",
    },
  },
  {
    path: "contact-us",
    component: ContactComponent,
    data: {
      title: "CONTACT US",
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
      initialNavigation: "enabledNonBlocking",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
