import { HotToastModule } from "@ngneat/hot-toast";
import { ContactComponent } from "./shared/components/pages/contact/contact.component";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { CarouselModule } from "ngx-owl-carousel-o";
import { AppRoutingModule, routes } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LayoutsModule } from "./layouts/layouts.module";
import { SharedModule } from "./shared/shared.module";
import { AboutUsComponent } from "./shared/components/pages/about-us/about-us.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [AppComponent, AboutUsComponent, ContactComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    LayoutsModule,
    HttpClientModule,
    HotToastModule.forRoot(),
    CarouselModule,
    // ScrollToModule,
    SharedModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
