<!-- testimonial section start-->
<section
  class="agency testimonial format testimonial-bg"
  [ngStyle]="{
    'background-image': 'url(assets/images/agency/testimonial/bg.png)'
  }"
>
  <div class="container">
    <div class="row">
      <div class="col-md-5">
        <div class="format-container m-b-50">
          <h6 class="borders-before">
            <span>Our Brands</span>
          </h6>
          <div class="format-head-text">
            <h2 class="text-white">
              Wide variety of brands<span class="block-span">we own</span>
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <owl-carousel-o
          [options]="testimonialCarouselOptions"
          class="testimonial-slider"
        >
          <ng-container *ngFor="let user of users">
            <ng-template carouselSlide class="item">
              <div class="testimonial-container testimonial-container2 m-0">
                <!-- <div class="d-flex">
                  <img [src]="user.img" alt="" class="img-fluid m-r-25" />
                  <div class="center-content">
                    <h6 class="name">{{ user.name }}</h6>
                    <h6 class="post font-primary">{{ user.designation }}</h6>
                  </div>
                </div> -->
                <div
                  class="testimonial-info d-flex flex-column align-content-center"
                >
                  <!-- <p class="m-b-20 para2">{{ user.review }}</p> -->
                  <img [src]="user.img" alt="" class="img-fluid m-r-25" />

                  <!-- <div class="primary-border text-center"></div> -->
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- testimonial section end-->
