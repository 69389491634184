<!--agency content start-->
<section class="agency agency-content about-bg">
  <h3 class="text-as-shadow">
    About <br />
    us
  </h3>
  <div class="container">
    <div class="row">
      <div class="col-xl-9 offset-xl-3 col-md-9 offset-md-3">
        <div class="agency-header-center-container">
          <div class="borders"></div>
          <div class="agency-head">
            <h3 class="agency-head-text">
              Our Guiding <span class="block-span">Principles</span>
            </h3>
          </div>
        </div>

        <p class="agency-para">
          We stand by the principles of quality, sustainability, and customer
          satisfaction. Our commitment to sourcing the freshest, locally-sourced
          ingredients ensures that each bite and sip is a celebration of flavor
          and eco-consciousness.
        </p>
        <a
          [routerLink]="['about-us']"
          class="btn btn-default btn-gradient text-white"
          >Read more</a
        >
      </div>
    </div>
  </div>
  <div class="side-img set-abs left-0 top-0" [@fadeInLeft]="fadeInLeft">
    <img
      src="assets/images/agency/site-images/cook.png"
      alt=""
      class="img-fluid about-img"
    />
  </div>
  <!-- <img
    src="assets/images/agency/agency-abs-img.png"
    alt=""
    class="img-fluid blue-img"
  /> -->
</section>
<!--agency content end-->
