import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { ColorScssService } from "./../../../service/color-scss.service";
import { Component, OnInit, ViewChild, inject } from "@angular/core";
import emailjs from "@emailjs/browser";
import { environment } from "src/environments/environment";
import { HotToastService } from "@ngneat/hot-toast";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  colorPicker: ColorScssService = inject(ColorScssService);
  loading: boolean = false;
  form: FormGroup;
  private toast: HotToastService = inject(HotToastService);
  @ViewChild("formDirective")
  formDirective: FormGroupDirective;
  templateParams: {
    from_name: string;
    email: string;
    message: string;
    to_name: string;
  };
  private fb: FormBuilder = inject(FormBuilder);

  ngOnInit(): void {
    this.colorPicker.setColorScheme("inner-page");
    this.form = this.fb.group({
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required]),
      message: new FormControl("", [
        Validators.required,
        Validators.maxLength(300),
        Validators.minLength(10),
      ]),
    });
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.templateParams = {
        from_name: this.form.controls["name"].value?.trim(),
        email: this.form.controls["email"].value?.trim(),
        message: this.form.controls["message"].value?.trim(),
        to_name: "Kemmale Support",
      };
      this.loading = true;
      this.form.disable();
      emailjs
        .send(
          "service_wqnmncc",
          "template_calueyg",
          this.templateParams,
          environment.emailJsPubKey
        )
        .then(
          (response) => {
            this.toast.success("Message sent successfully!", {
              position: "bottom-center",
            });
            this.form.reset();
            this.formDirective.resetForm();
            this.loading = false;
            this.form.enable();
          },
          (err) => {
            this.toast.error(
              "Message sending failed! Please drop an email to us on the above mentioned emails!"
            );
            console.log("FAILED...", err);
            this.loading = false;
            this.form.enable();
          }
        );
    }
  }
}
