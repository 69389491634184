import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-agency-counter",
  templateUrl: "./agency-counter.component.html",
  styleUrls: ["./agency-counter.component.scss"],
})
export class AgencyCounterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  counter = [
    {
      img: "assets/images/agency/site-images/delivery.png",
      count: 41234,
      text: "products delivered",
    },
    {
      img: "assets/images/agency/site-images/bread.png",
      count: 190,
      text: "Products",
    },
    {
      img: "assets/images/agency/site-images/employees.png",
      count: 425,
      text: "Employees",
    },
    {
      img: "assets/images/agency/counter/4.png",
      count: 22375,
      text: "Happy Clients",
    },
  ];
}
