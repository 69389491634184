import { Component, Inject, OnInit } from "@angular/core";
import { ColorScssService } from "src/app/shared/service/color-scss.service";

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.scss"],
})
export class AboutUsComponent implements OnInit {
  constructor(@Inject(ColorScssService) public colorPicker: ColorScssService) {}
  ngOnInit(): void {
    this.colorPicker.setColorScheme("inner-page");
  }
}
