<!--copyright start-->
<div class="agency copyright">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="link-horizontal">
          <ul>
            <li>
              <a
                class="copyright-text"
                (click)="openPrivacyPolicy(content_privacy_policy)"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a class="copyright-text" (click)="openTandC(content_tandc)"
                >Terms &amp; Conditions</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-6">
        <div>
          <h6 class="copyright-text text-white text-right">
            Copyright {{ year }} Kemmale food and beverages
          </h6>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content_privacy_policy let-modal>
  <div class="modal-body">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="container">
      <div class="row">
        <h3>Privacy Policy</h3>

        <p class="lh-1 mt-2 mb-2">
          This Privacy Policy applies to the website www.kemmale.com (referred
          to as "the Website"), which is owned and operated by Kemmale Food and
          Beverages (referred to as "we," "us," or "our").
        </p>

        <p class="lh-1 mt-2 mb-2">
          At Kemmale Food and Beverages, we are committed to protecting the
          privacy and security of your personal information. This Privacy Policy
          outlines how we collect, use, disclose, and safeguard your data when
          you visit our Website.
        </p>

        <p class="lh-1 mt-2 mb-2"><strong>Information We Collect</strong></p>

        <p class="lh-1 mt-2 mb-2">
          Personal Information: We may collect personal information that you
          voluntarily provide to us, such as your name, email address, phone
          number, and other information required for inquiries, bookings, or
          purchases.
        </p>

        <p class="lh-1 mt-2 mb-2">
          Automated Information: We collect non-personal information
          automatically, including but not limited to your IP address, browser
          type, and device information, for analytical and security purposes.
        </p>

        <p class="lh-1 mt-2 mb-2">
          <strong>How We Use Your Information</strong><br />
        </p>

        <p class="lh-1 mt-2 mb-2">
          We use the information we collect for the following purposes:
        </p>
        <ul>
          <li class="disc">
            To provide and personalize our services, including responding to
            your inquiries and fulfilling your orders.
          </li>
          <li class="disc">
            To improve and optimize the functionality of our Website and your
            user experience.
          </li>
          <li class="disc">
            To communicate with you regarding updates, promotions, and important
            information.
          </li>
          <li class="disc">
            To maintain the security and integrity of our Website.
          </li>
          <li class="disc">
            To comply with legal requirements and protect our rights and
            interests.
          </li>
          <li class="disc">Disclosure of Your Information</li>
        </ul>
        <p class="lh-1 mt-2 mb-2"></p>

        <p class="lh-1 mt-2 mb-2">
          We may share your personal information with trusted third parties only
          under the following circumstances:
        </p>

        <p class="lh-1 mt-2 mb-2">With your consent.</p>
        <ul>
          <li class="disc">To comply with legal obligations.</li>
          <li class="disc">
            To facilitate services and transactions you have requested.
          </li>
          <li class="disc">
            To protect our rights, privacy, safety, or property.
          </li>
          <li class="disc">
            In the event of a merger, acquisition, or sale of all or a portion
            of our assets, your information may be transferred to the acquiring
            entity.
          </li>
          <li class="disc">Data Security</li>
        </ul>

        <p class="lh-1 mt-2 mb-2"></p>
        <p class="lh-1 mt-2 mb-2">
          We take the security of your personal information seriously. We employ
          reasonable administrative, technical, and physical measures to protect
          your data from unauthorized access, disclosure, alteration, and
          destruction.
        </p>

        <h5>Cookies</h5>

        <p class="lh-1 mt-2 mb-2">
          We use cookies to enhance your experience on our Website. You may
          choose to disable cookies in your browser settings, but this may
          affect the functionality of certain features.
        </p>

        <h5>Third-Party Links</h5>

        <p class="lh-1 mt-2 mb-2">
          Our Website may contain links to third-party websites. Please note
          that we are not responsible for the privacy practices or content of
          these websites. We recommend that you review their privacy policies
          before providing any personal information.
        </p>

        <h5>Your Choices</h5>

        <p class="lh-1 mt-2 mb-2">
          You have the right to access, update, or delete your personal
          information. If you wish to do so, or if you have any questions or
          concerns about your privacy, please contact us at support@kemmale.com.
        </p>

        <h5>Changes to this Privacy Policy</h5>

        <p class="lh-1 mt-2 mb-2">
          We reserve the right to update or modify this Privacy Policy at any
          time. Any changes will be effective upon posting on the Website with
          the updated effective date.
        </p>

        <p class="lh-1 mt-2 mb-2">
          By using our Website, you consent to the practices described in this
          Privacy Policy. If you do not agree to this Privacy Policy, please do
          not use the Website.
        </p>

        <p class="lh-1 mt-2 mb-2">
          Thank you for choosing Kemmale Food and Beverages. We appreciate your
          trust and confidence in us. If you have any questions or feedback
          about this Privacy Policy, please contact us at support@kemmale.com
        </p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #content_tandc let-modal>
  <div class="modal-body">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="container">
      <div class="row">
        <h3><strong>Terms and Conditions</strong></h3>

        <p class="lh-1 mt-2 mb-2">
          Please read these Terms and Conditions ("Terms") carefully before
          using the website www.kemmale.com (referred to as "the Website") owned
          and operated by Kemmale Food and Beverages (referred to as "we," "us,"
          or "our").
        </p>

        <p class="lh-1 mt-2 mb-2">
          By accessing or using the Website, you agree to be bound by these
          Terms. If you do not agree with these Terms, please do not use the
          Website.
        </p>

        <p class="lh-1 mt-2 mb-2"><strong>Use of the Website</strong></p>

        <p class="lh-1 mt-2 mb-2">
          <strong>Eligibility:</strong> You must be at least 18 years old or the
          legal age of majority in your jurisdiction to use our Website. By
          using the Website, you represent and warrant that you meet this
          requirement.
        </p>

        <p class="lh-1 mt-2 mb-2">
          <strong>License:</strong> We grant you a limited, non-exclusive,
          non-transferable license to access and use the Website for personal,
          non-commercial purposes. You may not use the Website for any unlawful
          or unauthorized purpose.
        </p>

        <p class="lh-1 mt-2 mb-2">
          <strong>User Account:</strong> If you create an account on our
          Website, you are responsible for maintaining the confidentiality of
          your account information, including your username and password. You
          agree to accept responsibility for all activities that occur under
          your account.
        </p>

        <p class="lh-1 mt-2 mb-2">
          <strong>Prohibited Activities:</strong> You may not engage in any
          activity on the Website that is harmful, fraudulent, deceptive, or
          violates any applicable laws or regulations.
        </p>

        <p class="lh-1 mt-2 mb-2"><strong>Intellectual Property</strong></p>

        <p class="lh-1 mt-2 mb-2">
          The content on the Website, including but not limited to text,
          graphics, logos, images, and software, is protected by intellectual
          property laws and is the exclusive property of Kemmale Food and
          Beverages. You may not use, reproduce, or distribute any content from
          the Website without our prior written consent.
        </p>

        <p class="lh-1 mt-2 mb-2"><strong>Third-Party Links</strong></p>

        <p class="lh-1 mt-2 mb-2">
          Our Website may contain links to third-party websites. We do not
          endorse, control, or assume responsibility for the content or
          practices of any third-party websites. Your use of such websites is at
          your own risk.
        </p>

        <p class="lh-1 mt-2 mb-2"><strong>Disclaimer</strong></p>

        <p class="lh-1 mt-2 mb-2">
          The information provided on our Website is for general informational
          purposes only. We make no representations or warranties about the
          accuracy, completeness, or reliability of the content. Any reliance
          you place on such information is at your own risk.
        </p>

        <p class="lh-1 mt-2 mb-2"><strong>Limitation of Liability</strong></p>

        <p class="lh-1 mt-2 mb-2">
          To the fullest extent permitted by law, we shall not be liable for any
          direct, indirect, incidental, consequential, or punitive damages
          arising out of your access to or use of the Website.
        </p>

        <p class="lh-1 mt-2 mb-2">
          <strong>Changes to Terms and Conditions</strong>
        </p>

        <p class="lh-1 mt-2 mb-2">
          We reserve the right to modify or update these Terms at any time. Any
          changes will be effective upon posting on the Website with the updated
          effective date. Your continued use of the Website after any such
          changes constitutes your acceptance of the new Terms.
        </p>

        <p class="lh-1 mt-2 mb-2"><strong>Governing Law</strong></p>

        <p class="lh-1 mt-2 mb-2">
          These Terms are governed by and construed in accordance with the laws
          of Bharath, and any disputes shall be subject to the exclusive
          jurisdiction of the courts in that jurisdiction.
        </p>

        <p class="lh-1 mt-2 mb-2"><strong>Contact Information</strong></p>

        <p class="lh-1 mt-2 mb-2">
          If you have any questions or concerns about these Terms and
          Conditions, please contact us at support@kemmale.com.
        </p>
      </div>
    </div>
  </div>
</ng-template>
<!--copyright start-->
