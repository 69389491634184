import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

// Menu
export interface Menu {
  path?: string;
  title?: string;
  type?: string;
  icon?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  megaMenu?: boolean;
  megaMenuType?: string; // small, medium, large
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  MENUITEMS: Menu[] = [
    {
      title: "Home",
      type: "link",
      path: "/",
    },
    {
      title: "Gallery",
      type: "sub",
      children: [
        {
          title: "Kemmale Food and Beverages",
          type: "link",
          path: "/gallery/kemmale-food-and-beverages",
        },
        {
          title: "Kemmale Group of Hotels",
          type: "link",
          path: "/gallery/kemmale-group-of-hotels",
        },
        {
          title: "Media",
          type: "link",
          path: "/gallery/media",
        },
      ],
    },
    {
      title: "Products",
      type: "link",
      path: "/gallery/products",
    },
    {
      title: "About us",
      type: "link",
      path: "/about-us",
    },
    {
      title: "Contact us",
      type: "link",
      path: "/contact-us",
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
