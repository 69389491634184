<app-agency-nav [isDark]="true"></app-agency-nav>
<app-breadcrumb></app-breadcrumb>

<section
  class="music bg-footer mb-100"
  [ngStyle]="{ 'background-image': 'url(assets/images/music/footer-bg.jpg)' }"
>
  <div class="container">
    <h4 class="title text-white">Send your message</h4>
    <form
      class="form form-footer p-t-50 mb-5"
      [formGroup]="form"
      (ngSubmit)="submit()"
      #formDirective="ngForm"
    >
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <input type="text" formControlName="name" placeholder="your name" />
            <small
              class="text-danger"
              *ngIf="
                form.controls['name'].hasError('required') &&
                form.controls['name'].touched
              "
              >Name is <strong>required!</strong></small
            >
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <input
              type="email"
              formControlName="email"
              placeholder="your email"
            />
            <small
              class="text-danger"
              *ngIf="
                form.controls['email'].hasError('required') &&
                form.controls['email'].touched
              "
              >Email is <strong>required!</strong></small
            >
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <textarea
              class="form-control"
              rows="6"
              formControlName="message"
              placeholder="your message"
              [rows]="5"
              id="message"
            ></textarea>
            <small
              class="text-danger"
              *ngIf="
                form.controls['message'].hasError('required') &&
                form.controls['message'].touched
              "
              >Message is <strong>required!</strong></small
            >
            <small
              class="text-danger"
              *ngIf="
                !form.controls['message'].hasError('required') &&
                form.controls['message'].touched &&
                (form.controls['message'].hasError('minlength') ||
                  form.controls['message'].hasError('maxlength'))
              "
              >Minimum length is <strong>10</strong> and Maximum length is
              <strong>300!</strong></small
            >
          </div>
        </div>
        <div class="booking">
          <button
            type="submit"
            class="btn btn-default primary-btn m-0-auto bg-black"
          >
            <span *ngIf="!loading">send</span>
            <div
              *ngIf="loading"
              class="spinner-grow spinner-grow-sm"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
      </div>
    </form>
    <div class="row mt-5">
      <div class="col-sm-6">
        <div class="contact-details text-center">
          <h4 class="contact-heading text-white">
            For product or sales related enquiries
          </h4>
          <h6 class="contact-sub-text text-white">
            Email Us at -
            <a
              href="mailto:support@kemmale.com"
              class="text-white badge bg-primary fs-100"
              target="_blank"
              >support@kemmale.com</a
            >
          </h6>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="contact-details text-center">
          <h4 class="contact-heading text-white">For job enquiries</h4>
          <h6 class="contact-sub-text text-white">
            Email Us at -
            <a
              href="mailto:hr@kemmale.com"
              class="text-white badge bg-primary fs-100"
              target="_blank"
              >hr@kemmale.com</a
            >
          </h6>
        </div>
      </div>
    </div>
  </div>
</section>

<app-agency-footer></app-agency-footer>
<app-agency-copyright></app-agency-copyright>
