<app-agency-nav [isDark]="true"></app-agency-nav>
<app-breadcrumb></app-breadcrumb>
<router-outlet></router-outlet>
<section class="event subscribe subscribe-3 set-relative p-t-0">
  <div class="circle"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
        <div class="subscribe">
          <div class="center-content">
            <div class="form-group">
              <div class="flex">
                <input
                  title="Order text"
                  type="text"
                  disabled
                  class="form-control text-capitalize shadow"
                  value="Order our cakes online"
                  name="text"
                />
                <div class="button-primary">
                  <a
                    href="https://bakestudio.in"
                    class="btn btn-default primary-btn text-white text-capitalize"
                    target="_blank"
                    rel="noopener"
                  >
                    Order now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-agency-footer></app-agency-footer>
<app-agency-copyright></app-agency-copyright>
