import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MenuComponent } from "./components/navigation/menu/menu.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
import { HeaderComponent } from "./components/pages/header/header.component";
import { BreadcrumbComponent } from "./components/pages/breadcrumb/breadcrumb.component";
import { FooterComponent } from "./components/pages/footer/footer.component";
import { RouterModule } from "@angular/router";
import { ToastrModule } from "ngx-toastr";
import { OrderByPipe } from "./pipes/order-by.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { LoaderComponent } from "./components/loader/loader.component";
import { AgencyHeaderComponent } from "../layouts/agency/agency-header/agency-header.component";
import { AgencyFooterComponent } from "../layouts/agency/agency-footer/agency-footer.component";
import { AgencyNavComponent } from "../layouts/agency/agency-nav/agency-nav.component";
import { AgencyCopyrightComponent } from "../layouts/agency/agency-copyright/agency-copyright.component";

@NgModule({
  declarations: [
    MenuComponent,
    TapToTopComponent,
    HeaderComponent,
    BreadcrumbComponent,
    FooterComponent,
    OrderByPipe,
    SafePipe,
    LoaderComponent,
    AgencyHeaderComponent,
    AgencyFooterComponent,
    AgencyNavComponent,
    AgencyCopyrightComponent,
  ],
  exports: [
    MenuComponent,
    LoaderComponent,
    TapToTopComponent,
    HeaderComponent,
    BreadcrumbComponent,
    FooterComponent,
    OrderByPipe,
    SafePipe,
    AgencyHeaderComponent,
    AgencyFooterComponent,
    AgencyNavComponent,
    AgencyCopyrightComponent,
  ],
  imports: [CommonModule, RouterModule, ToastrModule.forRoot()],
})
export class SharedModule {}
