<!-- Nav Start-->
<header class="agency nav-abs custom-scroll" [class.dark]="isDark">
  <div class="container">
    <div class="row">
      <div class="col">
        <nav>
          <a href="#" class="logo-light d-inline-block m-r-auto"
            ><img
              src="assets/images/agency/site-images/logo.png"
              alt=""
              class="img-fluid"
          /></a>
          <app-menu></app-menu>
        </nav>
      </div>
    </div>
  </div>
</header>
<!-- Nav end-->
