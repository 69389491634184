// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDzfGuTFC47yqcAqdrFNgsbOBnuhue7VCw",
    authDomain: "kemmale2023.firebaseapp.com",
    projectId: "kemmale2023",
    storageBucket: "kemmale2023.appspot.com",
    messagingSenderId: "489217270737",
    appId: "1:489217270737:web:3d4d072f0bb84e996576f0",
    measurementId: "G-YGVMNZ9Z0S",
  },
  emailJsPubKey: "ANwBCKp_CCuRmiavm",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
