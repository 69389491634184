<!--footer start-->
<footer class="agency footer2 p-b-0">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-12 set-first">
        <div class="logo-sec">
          <div class="footer-title mobile-title">
            <h3 class="text-white">About Us</h3>
          </div>
          <div class="footer-contant">
            <img
              src="assets/images/agency/site-images/logo.png"
              alt=""
              class="img-fluid footer-logo"
            />
            <div class="footer-para">
              <h6 class="text-white para-address">
                Kemmale Food and Beverages
              </h6>
              <h6 class="text-white para-address">
                Intech City, 1st Floor, Near Vijayabank
              </h6>
              <h6 class="text-white para-address">
                Korangrapady, Udupi, Karnataka 574118.
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12">
        <div class="footer-title mobile-title">
          <h3 class="text-white">services</h3>
        </div>
        <div class="footer-contant">
          <h5 class="footer-headings">services</h5>
          <div>
            <ul class="footer-lists op-text">
              <li class="">
                <a href="#">Bakery</a>
              </li>
              <li class="">
                <a href="#">Cakes N Desserts</a>
              </li>
              <li class="">
                <a href="#">Beverages</a>
              </li>
              <li class="">
                <a href="#">Fine Dine Restaurants</a>
              </li>
              <li class="">
                <a href="#">Outdoor Catering</a>
              </li>
              <li class="">
                <a href="#">Party Events</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12">
        <div class="footer-title mobile-title">
          <h3 class="text-white">brands</h3>
        </div>
        <div class="footer-contant">
          <h5 class="footer-headings">brands</h5>
          <div>
            <ul class="footer-lists op-text">
              <li class="">
                <a rel="noopener" href="https://bakestudio.in" target="_blank"
                  >Bakestudio</a
                >
              </li>
              <li class="">
                <a href="#">Fish Factory Restaurant</a>
              </li>
              <li class="">
                <a href="#">Namaskar Restaurant</a>
              </li>
              <li class="">
                <a href="#">The Flavors catering</a>
              </li>
              <li class="">
                <a href="#">Fruitify Beverage</a>
              </li>
              <li class="">
                <a href="#">Green Tadka Restaurant</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12">
        <div class="footer-title mobile-title">
          <h3 class="text-white">Contact us</h3>
        </div>
        <div class="footer-contant">
          <h5 class="footer-headings">Contact us</h5>
          <div>
            <ul class="footer-lists op-text">
              <li class="">
                <em class="fa fa-envelope-o mr-3"></em>
                <a
                  href="mailto:support@kemmale.com"
                  style="text-transform: none"
                  >support@kemmale.com</a
                >
              </li>
              <li class="">
                <em class="fa fa-phone mr-3"></em>
                <a href="tel:+91-9844617645">+91-9844617645</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--footer end-->
  </div>
</footer>
<!--footer end-->
<app-tap-to-top></app-tap-to-top>
