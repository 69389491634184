<app-agency-nav [isDark]="true"></app-agency-nav>
<app-breadcrumb></app-breadcrumb>

<!-- about section Start-->
<section class="gym format">
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="center-text">
          <div class="format-sub-text mb-0">
            <p class="p-light about-para">
              Welcome to Kemmale - a dynamic conglomerate that has been
              redefining the realms of hospitality and gastronomy since its
              inception in 2015. Our journey began with the establishment of two
              distinct yet harmonious divisions - Kemmale Food and Beverages and
              Kemmale Group of Hotels.
            </p>
            <div class="title title2 title-inner">
              <div class="main-title">
                <h4 class="font-primary borders text-center m-b-0">
                  <span
                    >Bake Studio: From Humble Beginnings to Culinary
                    Excellence</span
                  >
                </h4>
              </div>
            </div>
            <p class="p-light about-para">
              As a small-scale bakery, Kemmale Food and Beverages initially
              supplied the region with breads, pastries, sweets, and
              confectionery. Over the years, our dedication to quality and
              customer satisfaction propelled us to become the largest bakery in
              Coastal Karnataka. Today, with a workforce of over 450 employees
              spread across Tier 2 and Tier 3 Cities, we operate 19 Bake Studio
              outlets, showcasing our commitment to growth and accessibility.
            </p>
            <p class="p-light about-para">
              Our product line has expanded to include 200 different types of
              bakery items, ranging from traditional breads and pastries to
              muffins, cakes, and cookies. Despite the vast product base, we
              maintain a reputation for superior customer satisfaction and
              consistently excellent quality.
            </p>
            <div class="title title2 title-inner">
              <div class="main-title">
                <h4 class="font-primary borders text-center m-b-0">
                  <span>Diversification and Regional Impact</span>
                </h4>
              </div>
            </div>
            <p class="p-light about-para">
              Kemmale Food and Beverages has diversified its product range to
              meet the evolving needs of our growing customer base. We take
              pride in being a proud regional supplier to a diverse clientele,
              including globally recognized hypermarkets, supermarkets, coffee
              shops, fast-food outlets, and high-profile catering companies
              across Karnataka.
            </p>
            <p class="p-light about-para">
              Our bakery operates with ultra-modern, state-of-the-art, fully and
              semi-automated production lines that run 365 days a year. With the
              capacity to produce large volumes of products daily, our
              commitment to freshness is unwavering. A team of trained employees
              and a fleet of recognizable vehicles ensure that our goods are
              delivered fresh to our customers consistently.
            </p>
            <div class="title title2 title-inner">
              <div class="main-title">
                <h4 class="font-primary borders text-center m-b-0">
                  <span>Innovation and E-Commerce Presence</span>
                </h4>
              </div>
            </div>
            <p class="p-light about-para">
              Kemmale Food and Beverages remains at the forefront of the
              industry by investing in research and development. Our teams are
              dedicated to continuous innovation, introducing new and exciting
              products to the region. This commitment to staying ahead of the
              competition has solidified our position as a leader in the bakery
              sector.
            </p>
            <p class="p-light about-para">
              In addition to our physical presence, we've embraced the digital
              age with an e-commerce website,
              <a rel="noopener" href="https://www.bakestudio.in" target="_blank"
                >www.bakestudio.in</a
              >. Here, customers can conveniently order cakes and other products
              online, further enhancing accessibility and convenience.
            </p>
            <div class="title title2 title-inner">
              <div class="main-title">
                <h4 class="font-primary borders text-center m-b-0">
                  <span
                    >Kemmale Group of Hotels: Elevating Hospitality to New
                    Heights</span
                  >
                </h4>
              </div>
            </div>
            <p class="p-light about-para">
              Step into the world of luxury and unparalleled hospitality with
              Kemmale Group of Hotels. With a proud portfolio of 10 restaurants
              with brand name “Fish Factory – Seafood Restaurant”, “Namaskara –
              Pure Veg Restaurant”, “Green Tadka – Dhabha Style Restaurant”,
              each underpinned by our commitment to excellence, we have set the
              standard for premium accommodation and dining. Our hotels are
              designed to be havens of comfort, seamlessly blending modern
              amenities with timeless elegance. Whether you are a business
              traveler seeking a sophisticated retreat or a leisure guest in
              pursuit of a memorable dining experience, Kemmale Group of Hotels
              welcomes you with open arms.
            </p>
            <div class="title title2 title-inner">
              <div class="main-title">
                <h4 class="font-primary borders text-center m-b-0">
                  <span
                    >Our Ethos: Quality, Innovation, and Customer
                    Satisfaction</span
                  >
                </h4>
              </div>
            </div>
            <p class="p-light about-para">
              At the heart of Kemmale is a steadfast commitment to quality,
              innovation, and customer satisfaction. We believe in continuously
              pushing boundaries, adapting to changing trends, and delighting
              our patrons with unparalleled experiences. Our success is not just
              measured by our business achievements but by the smiles we put on
              the faces of our valued customers.
            </p>
            <div class="title title2 title-inner">
              <div class="main-title">
                <h4 class="font-primary borders text-center m-b-0">
                  <span>A Glimpse into the Future</span>
                </h4>
              </div>
            </div>
            <p class="p-light about-para">
              As we stride confidently into the future, Kemmale remains
              dedicated to expanding our horizons, innovating in the culinary
              and hospitality landscape, and creating enduring relationships
              with our patrons. Join us on this exciting journey as we continue
              to redefine the art of dining and hospitality.
            </p>
            <p class="p-light about-para">
              Thank you for choosing Kemmale. We look forward to serving you
              with passion, dedication, and the unmistakable Kemmale touch.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- about section end-->

<app-agency-footer></app-agency-footer>
<app-agency-copyright></app-agency-copyright>
