import { Component, OnInit, inject } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-agency-copyright",
  templateUrl: "./agency-copyright.component.html",
  styleUrls: ["./agency-copyright.component.scss"],
})
export class AgencyCopyrightComponent implements OnInit {
  modalService: NgbModal = inject(NgbModal);

  year = new Date().getFullYear();

  constructor() {}

  ngOnInit() {}

  openTandC(content) {
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  openPrivacyPolicy(content) {
    this.modalService.open(content, { centered: true, size: "lg" });
  }
}
