import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-agency-testimonial",
  templateUrl: "./agency-testimonial.component.html",
  styleUrls: ["./agency-testimonial.component.scss"],
})
export class AgencyTestimonialComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  users = [
    {
      img: "assets/images/agency/site-images/bkj.jpg",
    },
    {
      img: "assets/images/agency/site-images/ff.jpg",
    },
    {
      img: "assets/images/agency/site-images/flavour.jpg",
    },
    {
      img: "assets/images/agency/site-images/namaskar.jpg",
    },
    {
      img: "assets/images/agency/site-images/greentadka.jpg",
    },
    {
      img: "assets/images/agency/site-images/fruitify.jpg",
    },
  ];

  testimonialCarouselOptions = {
    items: 3,
    margin: 65,
    nav: true,
    dots: false,
    navText: [
      '<img src="/assets/images/agency/testimonial/left.png">',
      '<img src="/assets/images/agency/testimonial/right.png">',
    ],
    autoplay: true,
    slideSpeed: 500,
    paginationSpeed: 400,
    loop: true,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      575: {
        items: 2,
        margin: 10,
      },
      991: {
        items: 2,
        margin: 30,
      },
      1199: {
        items: 3,
        margin: 30,
      },
    },
  };
}
