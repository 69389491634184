<!--counter section start-->
<section
  class="agency counter counter-bg"
  [ngStyle]="{
    'background-image': 'url(assets/images/agency/testimonial/bg.png)'
  }"
>
  <div class="container">
    <div class="row">
      <div
        class="col-md-3 col-6 counter-container"
        *ngFor="let data of counter"
      >
        <div class="counters hover-counter">
          <img [src]="data.img" alt="" class="img-fluid counter-img" />
          <div class="counter-text">
            <div class="count-number">
              <h2 class="counts text-center">{{ data.count }}+</h2>
            </div>
            <div class="count-desc">
              <h5 class="text-center">{{ data.text }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--counter section end-->
